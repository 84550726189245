"use client";

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect } from 'react';
import { ThemeProvider } from "styled-components";
import theme from "src/theme/theme";
import "../sass/App.scss";
import { AuthContextProvider } from '@auth/useAuth';
import { AppContainer } from "@common";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ToastProvider from '@components/Toaster/ToastProvider';
import StyledComponentsRegistry from "./lib/registry";

if (process.env.NEXT_PUBLIC_DD_ENABLED === "true") {
    datadogRum.init({
        applicationId: "d41319af-da93-4f01-b81b-351260d3a4db",
        clientToken: "pub5c2894bdbeac89e599bfe7789f451dfd",
        site: "datadoghq.com",
        env: process.env.NEXT_PUBLIC_APP_ENV,
        service: "merchant-onboarding",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "allow",
        version: process.env.NEXT_PUBLIC_APP_VERSION,
    });
    datadogLogs.init({
        clientToken: 'pub5c2894bdbeac89e599bfe7789f451dfd',
        env: process.env.NEXT_PUBLIC_APP_ENV,
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        service: 'merchant-onboarding',
        sessionSampleRate: 100,
        site: 'datadoghq.com',
        version: process.env.NEXT_PUBLIC_APP_VERSION,
    });
    datadogRum.startSessionReplayRecording();
}

const RootLayout = ({ children, }: { children: React.ReactNode }) => {
    const queryClient = new QueryClient();

    useEffect(() => {
        if (window && window.innerWidth < 575) {
            const vh = window.innerHeight * 0.01;

            document.documentElement.style.setProperty("--vh", `${vh}px`);
            window.addEventListener("resize", () => {
                document.documentElement.style.setProperty(
                    "--vh",
                    `${window.innerHeight * 0.01}px`
                );
            });
        }
    }, []);

    return <html lang="en">
        <head>
            <title>Application | Gravity Payments</title>
            <meta name="title" content="Application | Gravity Payments" />
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimal-ui"/>
            <meta name="description" content="The most trusted name in credit card processing. Accept payments any time, any where. Gravity integrates with hundreds of hardware and software solutions." />
            <link rel="stylesheet" href="https://use.typekit.net/qiv4ryh.css" />
        </head>
        <body className='overflow-hidden'>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <AppContainer>
                        <StyledComponentsRegistry>
                            <AuthContextProvider>
                                <ToastProvider>
                                    {children}
                                </ToastProvider>
                            </AuthContextProvider>
                        </StyledComponentsRegistry>
                    </AppContainer>
                </QueryClientProvider>
            </ThemeProvider>
        </body>
    </html>;
}
export default RootLayout;
